import { defineStore } from 'pinia'

interface State {
  sidebar_left: boolean
  sidebar_left_mini: boolean
  sidebar_right: boolean
}

export const useSidebarStore = defineStore({
  id: 'sidebar',
  state: (): State => ({
    sidebar_left: false,
    sidebar_left_mini: false,
    sidebar_right: false,
  }),
  getters: {
    sidebarLeftOpened(): boolean {
      return this.sidebar_left && !this.sidebar_left_mini
    },
  },
  actions: {
    SIDEBAR_L_TOGGLE(mdAndUp: boolean) {
      if (!this.sidebar_left) {
        this.sidebar_left_mini = false
        this.sidebar_left = true
      }
      else if (mdAndUp) {
        this.sidebar_left_mini = !this.sidebar_left_mini
      }
      else {
        this.sidebar_left = !this.sidebar_left
      }
    },
    SIDEBAR_R_TOGGLE(payload: boolean) {
      this.sidebar_right = payload
    },
  },
})
